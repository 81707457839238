
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  data(){
    return {
      resizeTimeout: 0
    }
  },

  // methods: {

  //   onResize(e:any) {
  //     if(this.resizeTimeout){
  //       clearTimeout(this.resizeTimeout)
  //     }
  //     this.resizeTimeout = setTimeout(() => {
  //       this.updateWindowAspects_root()
  //       this.$emitter.emit("toggle-sidebar", window.screen.width)
  //     }, 500);
  //   },

  //   updateWindowAspects_root() {
  //     // this.updateWindowAspects()
  //     // EB.$emit(EB_ACTION.WIN_RESIZE)
  //     // if(this.isSockeClusterActive == true && this.isModerator == false){
  //     //    this.sendParamsViaWebsox({screenSize: {width: window.innerWidth, height: window.innerHeight}})
  //     // }
  //   },
    
  // },
 
  // mounted(){
  //   this.resizeTimeout

	//   // this.updateWindowAspects()

  //   window.addEventListener("resize", this.onResize);
  // },

  // unmounted () {
  //   window.removeEventListener("resize", this.onResize);
  // },

});
