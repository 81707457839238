
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'HomeView',

  methods: {
   routeTo(path: string) {
    this.$router.push(path);
    
   } 
  }
});
